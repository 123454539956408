(function () {
    angular.module('evtviewer.imageViewerService', ['evtviewer.dataHandler'])

        .service('imageViewerModel', ['parsedData', function (parsedData) {
            var viewerModel = this;

            var options = {
                id: "osd-img",
                prefixUrl: "/openseadragon/images/",
                tileSources: [],
                showRotationControl: true,
                showNavigator: true,
                visibilityRatio: 1,
                defaultZoomLevel: 1,
                panHorizontal: true,
                constrainDuringPan: true,
                minZoomLevel: 0.8,
                maxZoomLevel: 2.0,
                wrapVertical: false,
                navigatorLeft: "94%",
                navigatorHeight: "100%",
                navigatorWidth: "50%",
                navigatorTop: "1%",
                preload: true
            };

            viewerModel.getOptions = function () {

                var pages = parsedData.getPages();
                var lenght = pages.length;
                var p;
                var pp;
                var source = "";
                for (var i = 0; i < lenght; i++) {
                    var imgobj = {
                        type: "image",
                        url: ""
                    };
                    p = pages[i];
                    pp = parsedData.getPage(p);
                    source = pp.source;
                    if (source !== undefined && source !== '' && source !== ' ' && source !== null) {
                        imgobj.url = source;
                        var IIIF_URI = source.match(/^(.*)\/(?:[^\/]+)\/(?:[^\/]+)\/(?:[^\/]+)\/(?<quality>:color|gray|bitonal|default)(?:\.([^\.]+))?$/i);
                        options.tileSources.push(IIIF_URI ? IIIF_URI[1]+'/info.json' : (config.iiifUrl ? config.iiifUrl+source.replace(/^\.\.\//,'').replace(/\//g,'!').match(/^([^\/]+?)(?:\.[^\.]+)?$/i)[1] : imgobj));
                    }
                }
                return options;
            };
        }]);
})();
